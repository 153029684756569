/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Styles
import styles from "./styles.css";

// Components
import RecentlyCarSectionSliderLanding from "../recently-car-section-slider-landing";
const Skeleton = loadable(() => import("./skeleton"));
import ArrowIcon from "../../shared/arrow";

// Constants
import { COOKIE_CONSTANTS, HOME_CAR_SECTION, NUMBER, SORT_DATA } from "../../../constants/app-constants";
import { defaultParams, defaultFilterPayload } from "../car-listing/constants";

// Utils
import updateRecentlyViewedCookie from "../../../utils/helpers/update-recently-viewed-cookie";
import parseCookie from "../../../utils/helpers/parse-cookie";
import scrollToTop from "../../../utils/helpers/scroll-to-top";
import Filters from "../../../utils/filters-v2";

// Tracking
import { trackMobileCustomEventsAE } from "../../../tracking";
import withLogin from "../with-login";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";
import { homeCarSections } from "./constants";

const HomeCarSection = ({
    sectionName = HOME_CAR_SECTION.RECOMMENDED,
    selectedCityName,
    popularCars,
    selectedCityCode,
    secureToken,
    isLoggedIn,
    hasCitySkip: citySkip,
    recommendedCars = [],
    recentlyViewedCars = [],
    isRecommendedCarsLoading,
    isRecentlyViewedCarsLoading,
    isPopularCarsLoading,
    fetchRecommendedCarsConnect,
    fetchRecentlyViewedCarsConnect,
    fetchPopularCarsConnect,
    clearAllFiltersConnect,
    isRecentlyViewedSSR,
    updateRecentlyViewedSSRStatusConnect,
    fetchWishlistedCarsListConnect,
    isPopularCarsSSR
}) => {

    const { customPush } = useCustomHistoryOperations();
    const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
    const recentlyViewedCarsData = JSON.parse(parseCookie(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS) || "[]") || [];
    const isPopupShown = parseCookie(COOKIE_CONSTANTS.LOCATION_POPUP_SHOWN) || false;
    const hasCitySkip = !isPopupShown || citySkip;
    const settings = { arrow: true };

    // RECOMMENDED section actions
    const fetchRecommendedCars = () => {
        const params = {
            ...defaultParams,
            variant: "filterV5",
            size: NUMBER.TEN
        };
        const { payload } = filters.getListingPayload({ ...defaultFilterPayload, hasCitySkip });
        fetchRecommendedCarsConnect(params, payload)
            // eslint-disable-next-line no-unused-vars
            .catch((error) => { });
    };

    const viewAllRecommended = async () => {
        clearAllFiltersConnect();
        trackMobileCustomEventsAE("homePageViewAll", { eventLabel: "recommended" });
        const { relativeURL } = filters.getListingPayload({ ...defaultFilterPayload, isGlobalUrl: true, hasCitySkip });
        await customPush(relativeURL);
        scrollToTop();
    };

    const handleRecommendedCarClick = async ({relativeURL, carId}) => {
        clearAllFiltersConnect();
        trackMobileCustomEventsAE("homePageCarClick", { eventLabel: "recommended", appointment_id: carId });
        await yieldToMainThread();
        window.open(relativeURL);
    };

    const onSwipeCallBackRecommended = ({appId}) => {
        trackMobileCustomEventsAE("homePageCarSwipe", { eventLabel: "recommended", appointment_id: appId});
    };

    // POPULAR CARS section actions
    const fetchPopularCars = () => {
        const filterPayload = {
            ...defaultFilterPayload,
            options: { sort: SORT_DATA.POPULAR_CARS.key }
        };
        const params = {
            ...defaultParams,
            variant: "filterV5",
            size: 10
        };
        const { payload } = filters.getListingPayload({ ...filterPayload, isGlobalUrl: true, hasCitySkip });
        fetchPopularCarsConnect(params, payload)
            // eslint-disable-next-line no-unused-vars
            .catch((error) => { });
    };

    const viewAllPopularCars = async () => {
        clearAllFiltersConnect();
        trackMobileCustomEventsAE("homePageViewAll", { eventLabel: "popular" });
        const { relativeURL } = filters.getListingPayload({ ...defaultFilterPayload, options: { sort: SORT_DATA.POPULAR_CARS.key }, isGlobalUrl: true, hasCitySkip });
        await customPush(relativeURL);
        scrollToTop();
    };

    const handlePopularCarClick = async ({relativeURL, carId}) => {
        clearAllFiltersConnect();
        trackMobileCustomEventsAE("homePageCarClick", { eventLabel: "popular", appointment_id: carId });
        await yieldToMainThread();
        window.open(relativeURL);
    };

    const onSwipeCallBackPopularCar = () => {
        trackMobileCustomEventsAE("homePageCarSwipe", { eventLabel: "popular" });
    };

    // RECENTLY_VIEWED section actions
    const fetchRecentlyViewedCars = () => {
        const filterPayload = {
            ...defaultFilterPayload,
            options: { sf: `appointmentId:${recentlyViewedCarsData?.join(";")}` }
        };
        const params = {
            ...defaultParams,
            variant: "filterV5",
            size: NUMBER.TEN
        };
        const { payload } = filters.getListingPayload({ ...filterPayload, hasCitySkip });
        fetchRecentlyViewedCarsConnect(params, payload).then(({ total, results }) => {
            // Handle edge case where recently viewed cars might get de-listed
            if (total < recentlyViewedCarsData?.length) {
                updateRecentlyViewedCookie(results);
            }
            // eslint-disable-next-line no-unused-vars
        }).catch((error) => { });
    };

    const viewAllRecentlyViewed = async () => {
        clearAllFiltersConnect();
        trackMobileCustomEventsAE("homePageViewAll", { eventLabel: "recent" });
        const { relativeURL } = filters.getListingPayload({ ...defaultFilterPayload, isGlobalUrl: true, hasCitySkip });
        await customPush(`${relativeURL}&isRecentlyViewed=true`);
        scrollToTop();
    };

    const handleRecentlyViewedCarClick = async ({relativeURL, carId}) => {
        clearAllFiltersConnect();
        trackMobileCustomEventsAE("homePageCarClick", { eventLabel: "recent", appointment_id: carId });
        await yieldToMainThread();
        window.open(relativeURL);
    };

    const onSwipeCallBackRecentlyViewed = ({appId}) => {
        trackMobileCustomEventsAE("homePageCarSwipe", { eventLabel: "recent", appointment_id: appId});
    };

    useEffect(() => {
        if (sectionName === HOME_CAR_SECTION.RECOMMENDED) {
            fetchRecommendedCars();
        }
        if (sectionName === HOME_CAR_SECTION.POPULAR_CARS && !isPopularCarsSSR) {
            fetchPopularCars();
        }

        if (sectionName === HOME_CAR_SECTION.RECENTLY_VIEWED && !isRecentlyViewedSSR) {
            fetchRecentlyViewedCars();
        }
        updateRecentlyViewedSSRStatusConnect(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionName]);

    useEffect(() => {
        if (isLoggedIn && secureToken) {
            fetchWishlistedCarsListConnect(secureToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    return (
        <div styleName={"styles.homeCarSection"}>
            {sectionName === HOME_CAR_SECTION.RECOMMENDED && (
                <Fragment>
                    {isRecommendedCarsLoading ?
                        <div styleName={"styles.carSliderSection"}>
                            <Skeleton />
                        </div> :
                        !!recommendedCars?.length && (
                            <div styleName={"styles.carSliderSection"}>
                                <h3>Recommended cars <span styleName={"styles.borderSeprator"} />
                                    <span styleName={"styles.viewAll"} onClick={viewAllRecommended}>
                                        VIEW ALL CARS
                                        <span styleName={"styles.iconWrap"}>
                                            <ArrowIcon darkblue rotateBy={NUMBER.TWO_HUNDRED_SEVENTY} />
                                        </span>
                                    </span>
                                </h3>
                                <RecentlyCarSectionSliderLanding
                                    carList={recommendedCars}
                                    settings={settings}
                                    isLoggedIn={isLoggedIn}
                                    carsToShow={NUMBER.TEN}
                                    handleCarClick={handleRecommendedCarClick}
                                    onSwipeCallBack={onSwipeCallBackRecommended}
                                    sectionName={homeCarSections.RECOMMENDED_CARS}
                                />
                            </div>
                        )
                    }
                </Fragment>
            )}

            {sectionName === HOME_CAR_SECTION.POPULAR_CARS && (
                <Fragment>
                    {isPopularCarsLoading ?
                        <div styleName={"styles.carSliderSection"}>
                            <Skeleton />
                        </div> :
                        !!popularCars?.length && (
                            <div styleName={"styles.carSliderSection"}>
                                <h3>Popular Cars <span styleName={"styles.borderSeprator"} />
                                    <span styleName={"styles.viewAll"} onClick={viewAllPopularCars}>
                                        VIEW ALL CARS
                                        <span styleName={"styles.iconWrap"}>
                                            <ArrowIcon darkblue rotateBy={NUMBER.TWO_HUNDRED_SEVENTY} />
                                        </span>
                                    </span>
                                </h3>
                                <RecentlyCarSectionSliderLanding
                                    carList={popularCars}
                                    isLoggedIn={isLoggedIn}
                                    settings={settings}
                                    carsToShow={NUMBER.TEN}
                                    handleCarClick={handlePopularCarClick}
                                    onSwipeCallBack={onSwipeCallBackPopularCar}
                                />
                            </div>
                        )
                    }
                </Fragment>
            )}

            {sectionName === HOME_CAR_SECTION.RECENTLY_VIEWED && (
                <Fragment>
                    {isRecentlyViewedCarsLoading ?
                        <div styleName={"styles.carSliderSection"}>
                            <Skeleton />
                        </div> :
                        !!recentlyViewedCars?.length && (
                            <div styleName={"styles.carSliderSection"}>
                                <h3>Recently viewed cars <span styleName={"styles.borderSeprator"} />
                                    <span styleName={"styles.viewAll"} onClick={viewAllRecentlyViewed}>
                                        VIEW ALL CARS
                                        <span styleName={"styles.iconWrap"}>
                                            <ArrowIcon darkblue rotateBy={NUMBER.TWO_HUNDRED_SEVENTY} />
                                        </span>
                                    </span>
                                </h3>
                                <RecentlyCarSectionSliderLanding
                                    carList={recentlyViewedCars}
                                    settings={settings}
                                    isLoggedIn={isLoggedIn}
                                    carsToShow={NUMBER.TEN}
                                    handleCarClick={handleRecentlyViewedCarClick}
                                    onSwipeCallBack={onSwipeCallBackRecentlyViewed}
                                    preloadNumber={NUMBER.TWO}
                                    sectionName={homeCarSections.RECENTLY_VIEWED_CARS}
                                />
                            </div>
                        )
                    }
                </Fragment>
            )}
        </div>
    );
};

HomeCarSection.propTypes = {
    sectionName: PropTypes.string,
    selectedCityName: PropTypes.string,
    selectedCityCode: PropTypes.string,
    hasCitySkip: PropTypes.bool,
    fetchCarListConnect: PropTypes.func,
    recommendedCars: PropTypes.array,
    popularCars: PropTypes.array,
    isRecommendedCarsLoading: PropTypes.bool,
    recentlyViewedCars: PropTypes.array,
    isRecentlyViewedCarsLoading: PropTypes.bool,
    isPopularCarsLoading: PropTypes.bool,
    fetchRecommendedCarsConnect: PropTypes.func,
    fetchRecentlyViewedCarsConnect: PropTypes.func,
    fetchPopularCarsConnect: PropTypes.func,
    clearAllFiltersConnect: PropTypes.func,
    fetchListingCountConnect: PropTypes.func,
    secureToken: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    isRecentlyViewedSSR: PropTypes.bool,
    updateRecentlyViewedSSRStatusConnect: PropTypes.func,
    fetchWishlistedCarsListConnect: PropTypes.func,
    isPopularCarsSSR: PropTypes.bool
};

export default withLogin(HomeCarSection);
